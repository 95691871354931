.not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: var(--font-raleway);
    color: black;
    padding: 2rem;
    text-align: center;
}
.not-found img {
    width: 20rem;
    height: 20rem;
}
