.single-product {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 90%;
  margin-bottom: 3.5rem;
  justify-content: center;
  gap: 3rem;
}

.single-product-titles {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 8rem;
}

.addtocart .active-add-to-cart,
.addtocart .inactive-add-to-cart {
  width: max-content;
}

.single-product-titles .category {
  text-transform: uppercase;
  font-family: var(--font-roboto);
}

.product-showcase {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: start;
}

.product-showcase img {
  max-width: 100%;
  height: 20rem;
  object-fit: cover;
}

.single-product .data {
  flex-direction: column;
  gap: 2rem;
}

.single-product-titles .brand {
  font-weight: 600;
  font-size: 1.875rem;
}

.single-product-titles .name {
  font-weight: 400;
  font-size: 1.875rem;
  line-height: 3rem;
}

.single-product-price p {
  font-weight: 700;
}

.single-product-price {
  flex-direction: column;
  gap: 1rem;
}

.single-product .attribute {
  flex-direction: column;
  gap: 2rem;
}

.pricing-section {
  flex-direction: column;
  margin-top: 0.9rem;
  gap: 0.8rem;
}

.pricing-section h3 {
  font-weight: 700;
  font-size: 1.5rem;
  color: var(--c-text);
  font-family: var(--font-roboto);
}

.single-product .description {
  flex-direction: column;
  font-family: var(--font-roboto);
  font-weight: 400;
  font-size: 1rem;
  color: var(--c-text);
  line-height: 1.5rem;
}

.single-product-pricing .product-price {
  font-weight: 700;
  font-size: 1.5rem;
}

@media (max-width: 460px) {
  .single-product-titles {
    padding: 2rem;
  }
}
