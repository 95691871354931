@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

:root {
  --font-raleway: "Poppins", sans-serif;
  --font-roboto: "Roboto", sans-serif;
  --sans-pro: "Source Sans Pro", sans-serif;
  --c-text: rgb(28, 30, 33);
  --c-primary: rgb(240, 97, 97);
  --c-white: rgb(255, 255, 255);
  --c-gray: rgb(141, 143, 154);
  --product-card-box-shadow: 0px 40px 35px rgba(168, 172, 176, 0.19);
  --c-scrollbar-track: rgb(241, 241, 241);
  --c-scrollbar-handle: rgba(136, 136, 136, 0.18);
  --c-scrollbar-handle-hover: rgb(85, 85, 85);
}

* {
  margin: 0;
  padding: 0;
}

body {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  box-sizing: border-box;
}
body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
image {
  display: block;
}
ul,
ol,
li {
  padding: 0;
  margin: 0;
}

main {
  display: flex;
  flex-direction: column;
}

.single-product-titles .brand,
.single-product-titles .name,
.single-product-price p,
.single-product-pricing .product-price,
.cartoverlay-products h4,
.cartoverlay-products .empty-cart,
.cartoverlay-item-pricing .product-price,
.cartoverlay .payment button,
.cart-overlay-item-attr h3,
.cart h2,
.cart-products-single .cart-data h2,
.cart-item-pricing .product-price,
.cart-product-interaction p,
.cart-totals .cart-totals-sum,
.cart-totals p,
.order-btn {
  font-family: var(--font-raleway);
}
.cart-attr .selected-attr-box,
.cart-attr .unselected-attr-box,
.cart-overlay-item-attr .selected-attr-box,
.cart-overlay-item-attr .unselected-attr-box,
.single-product .attribute .attribute-button,
.quick-attribute .attribute-button {
  font-family: var(--sans-pro);
}

header,
header ul,
.initial-currency,
.currency-switcher,
.product,
.titles-block,
.cart-quantity,
.product-card,
.quick-addto-cart,
.quick-attribute,
.quick-addtocart .active-add-to-cart,
.quick-addtocart .inactive-add-to-cart,
.quick-attribute .attribute-button,
.quick-attribute .buttons,
.active-add-to-cart,
.inactive-add-to-cart,
.product-showcase,
.mini-pictures,
.single-product .data,
.single-product-price,
.single-product .attribute,
.single-product .attribute .buttons,
.pricing-section,
.single-product .attribute .buttons,
.single-product .attribute .attribute-button,
.single-product .description,
.cartoverlay-products,
.cartoverlay-grid,
.cartoverlay-product,
.cartoverlay-product-details,
.cartoverlay-product-button,
.cartoverlay-products-single,
.cart-overlay-item-data,
.cart-overlay-item,
.cart-overlay-item-attr,
.cart-overlay-item-attr .selected-attr-box,
.cart-overlay-item-attr .unselected-attr-box,
.cart-overlay-item-attr .selected-color-box-section,
.cart-overlay-item-attr .buttons,
.cartoverlay-product-interaction,
.cart-overlay-item,
.cart-overlay-item-data,
.cart-overlay-buttons,
.bag-count,
.bag-and-checkout-buttons,
.cart,
.cart-products-single,
.cart-products-single .cart-data,
.cart-attr,
.cart-attr .selected-attr-box,
.cart-attr .unselected-attr-box,
.cart-attr .selected-color-box-section,
.cart-attr .buttons,
.cart-content,
.cart-product-interaction,
.cart-product-button,
.cart-totals,
.cart-totals section {
  display: flex;
}

.cart-hero h2 {
  position: absolute;
  z-index: 3;
  font-family: var(--font-raleway);
  top: 50%;
  font-size: 4rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  color: var(--c-white);
}
.active-add-to-cart {
  flex-direction: column;
  align-items: center;
  padding: 1rem 2rem;
  width: 100%;
  height: 3.25rem;
  color: var(--c-white);
  border: none;
  font-family: var(--font-raleway);
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  background-color: var(--c-primary);
}
.inactive-add-to-cart {
  flex-direction: column;
  align-items: center;
  padding: 1rem 2rem;
  width: 100%;
  height: 3.25rem;
  color: gray;
  border: none;
  font-family: var(--font-raleway);
  font-weight: 600;
  font-size: 1rem;
  cursor: not-allowed;
  opacity: 0.8;
}

.success-alert {
  display: none;
}
.success-alert.visible {
  display: inline-block;
  position: fixed;
  top: 10%;
  right: 5%;
  background-color: rgba(219, 219, 219, 0.22);
  backdrop-filter: blur(5px);
  padding: 0.7rem;
  border-radius: 10px;
}
.success-alert.visible p {
  font-family: var(--font-raleway);
  font-size: 1rem;
  color: var(--c-text);
  font-weight: 500;
}
