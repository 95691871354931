.checkout {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    padding: 2rem;
    margin-bottom: 4rem;
}

.checkout-content {
    display: flex;
    flex-direction: column;
    gap: 4rem;
}

.checkout h1 {
    font-family: var(--font-raleway);
    font-size: 3rem;
    letter-spacing: 1px;
    text-align: center;
}

.checkout-content .multi-steps {
    display: flex;
    flex-direction: row;
    list-style: none;
    gap: 4rem;
    justify-content: center;
}

.checkout-content .multi-steps li {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}

.multi-steps .step-title {
    font-family: var(--font-raleway);
    color: grey;
}

.multi-steps .step-number {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    font-family: var(--font-raleway);
    color: black;
    font-weight: 600;
    border: 1px solid grey;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    padding: 6px;
}

.multi-steps .step-number.active {
    background-color: var(--c-primary);
    border-color: var(--c-primary);
}

.checkout-content form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 60%;
}

.checkout-content form h2 {
    font-family: var(--font-raleway);
    font-size: 2rem;
    letter-spacing: 1px;
    font-weight: 400;
}

.checkout-inputs {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.checkout-inputs input {
    padding: 1rem;
    border: 1px solid black;
}

.checkout .form-buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.checkout .active-add-to-cart {
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    color: var(--c-white);
    border: none;
    font-family: var(--font-raleway);
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
    background-color: var(--c-primary);
}

.checkout .go-back {
    flex-direction: column;
    align-items: center;
    padding: 1rem 2rem;
    width: 100%;
    height: 3.25rem;
    color: black;
    border: none;
    font-family: var(--font-raleway);
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
    opacity: 0.8;
}

.checkout .error {
    color: var(--c-primary);
    font-family: var(--font-raleway);
    font-weight: 100;
}

@media (max-width: 600px) {
    .checkout-content form {
        width: 90%;
    }
}
